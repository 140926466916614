/* Genel Stil */
.contact-section {
  padding: 40px 20px;
  background-color: #f4f4f4;
  text-align: center;
  position: relative;
}

.contact-section h2 {
  font-size: 2rem;
  margin-bottom: 30px;
  text-align: left; 
  color: #2c3e50;
  font-weight: 700;
  padding-bottom: 10px;
  width: 100%;
}

/* İletişim Bilgileri ve Harita Stilleri */
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: -80px;
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  position: relative;
}

.contact-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
}

.contact-item {
  flex: 1;
  margin: 10px; 
  text-align: center;
}

.contact-item h3 {
  font-size: 1.2rem; 
  margin-top: 10px;
}

.contact-item p {
  font-size: 1rem; 
  color: #555;
}

/* Google Maps Iframe Stili */
.contact-map {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 60%; 
  overflow: hidden;
  border-radius: 12px 12px 0 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 0;
}

.contact-map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* Telif Hakkı */
.contact-copyright {
  margin-top: 20px;
  font-size: 0.8rem; 
  color: #777;
}

/* Responsive Tasarım */
@media (max-width: 768px) {
  .contact-info {
    flex-direction: column;
    align-items: center;
  }

  .contact-item {
    margin: 10px 0; 
  }
  
  .contact-map {
    padding-bottom: 75%; 
    align-items: center;

  }
}

@media (max-width: 480px) {
  .contact-container {
    padding: 15px; 
    margin-top: 10px; 
  }

  .contact-item h3 {
    font-size: 1rem; 
  }

  .contact-item p {
    font-size: 0.9rem; 
  }

  .contact-map {
    padding-bottom: 75%; 
    align-items: center;

  }
}
