.references-section {
    padding: 40px 20px;
    background-color: #f8f9fa;
    color: #333;
    line-height: 1.6;
    max-width: 1200px;
    margin: 0 auto;
}

.references-section h2 {
    font-size: 2rem;
    margin-bottom: 30px;
    text-align: left; 
    color: #2c3e50;
    font-weight: 700;
    padding-bottom: 10px;
    width: 100%;
}

.references-section h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #2c3e50;
}

.references-section p {
    font-size: 1.1rem;
    margin-bottom: 15px;
    text-align: justify;
}

.references-section a {
    color: #0056b3;
    text-decoration: none;
    font-weight: 500;
}

.references-section a:hover {
    text-decoration: underline;
    color: #2c3e50;
}

/* Responsive Tasarım */
@media (max-width: 768px) {
    .references-section {
        padding: 30px 15px;
    }

    .references-section h2 {
        font-size: 1.75rem;
    }

    .references-section h3 {
        font-size: 1.25rem;
    }

    .references-section p {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .references-section {
        padding: 20px 10px;
    }

    .references-section h2 {
        font-size: 1.5rem;
    }

    .references-section h3 {
        font-size: 1.1rem;
    }

    .references-section p {
        font-size: 0.95rem;
    }
}
