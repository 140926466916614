/* General Styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  overflow-x: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #f8f8f8;
  color: #333;
  font-size: 14px;
}

.top-header {
  background-color: #f8f8f8;
}

.header-left p,
.header-right p {
  margin: 0;
}
/* hepsini mi alayım aq*/
.nav-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #333;
  color: white;
}

.nav-bar .logo {
  display: flex;
  align-items: center;
}

.nav-bar .logo img {
  height: 50px;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: inherit;
}


.nav-links a:hover {
  color: #ddd;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icons a {
  color: white;
  font-size: 20px;
}

.social-icons a:hover {
  color: #ddd;
}

/* Image Slider Styles */
.slider-container {
  position: relative;
  width: 100%;
  margin: auto;
  display: flex;
  overflow: hidden;
  height: 600px; /* Fixed height for the slider */
}

.slider-image {
  width: 100%;
  height: auto;
}

.arrow {
  cursor: pointer;
  font-size: 2em;
  color: white;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.arrow:hover {
  color: lightgray;
}


/* Component Section Styling */
section {
  width: 100%;
  max-width: 1200px;
  margin: 40px auto;
  padding: 0 20px;
}

/* Responsive Styling for Mobile */
@media (max-width: 768px) {
  .nav-bar {
    flex-direction: column;
    padding: 10px;
  }

  .nav-links {
    flex-direction: column;
    gap: 10px;
  }

  .logo img {
    height: 40px;
  }

  .slider-container {
    height: 300px;
  }

  .arrow {
    font-size: 24px;
  }

  header,
  .nav-bar {
    font-size: 12px;
  }

  section {
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  .slider-container {
    height: 200px;
  }

  .nav-bar {
    padding: 5px;
  }

  .nav-links {
    font-size: 12px;
  }

  .social-icons {
    font-size: 16px;
  }

  .arrow {
    font-size: 18px;
  }
}
