/* General Styling for Products Section */
.products-section {
    padding: 60px 20px;
    max-width: 1200px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); 
}

.products-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: flex-start; 
    text-align: left; 
}

.products-container h1,
.products-container h2 {
    font-size: 2rem;
    margin-bottom: 30px;
    text-align: left; 
    color: #2c3e50;
    font-weight: 700;
    padding-bottom: 10px;
    width: 100%;
}

.products-container p, 
.reporting-features-text span {  
    font-size: 1.2rem; 
    line-height: 1.8;
    color: #34495e; 
    margin-bottom: 20px; 
    max-width: 1000px;
    text-align: left;
    font-family: 'Roboto', sans-serif; 
}

.products-container h2 {
    font-size: 2rem;
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: 600;
    color: #2c3e50;
}

/* Image Styling */
.image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Görselleri yatayda ortala */
    gap: 20px; /* Görseller arasında boşluk bırak */
    margin-top: 20px;
    margin-bottom: 40px;
    width: 100%;
}

.product-image {
    width: 400px; /* Sabit genişlik */
    height: 300px; /* Sabit yükseklik */
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); 
    transition: transform 0.3s ease;
    object-fit: contain; /* Görsellerin düzgün yerleşmesini sağla */
}

.product-image:hover {
    transform: scale(1.05); 
}

/* Special Rule for Single Image */
.image-container.single-image .product-image {
    flex-grow: 1;
    max-width: 100%; 
}

/* Responsive Styling */
@media (max-width: 768px) {
    .products-container h1, 
    .products-container h2 {
        font-size: 2rem;
        text-align: left;
    }

    .products-container p, 
    .reporting-features-text span {
        font-size: 1rem;
        text-align: left;
    }

    .image-container {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }

    .product-image {
        width: 180px; /* Küçük ekranlar için daha küçük sabit genişlik */
        height: 130px; /* Küçük ekranlar için daha küçük sabit yükseklik */
    }
}

@media (max-width: 480px) {
    .products-container h1, 
    .products-container h2 {
        font-size: 1.8rem;
    }

    .products-container p,
    .reporting-features-text span {
        font-size: 0.95rem;
    }

    .image-container {
        gap: 15px;
    }

    .product-image {
        width: 150px; /* Mobilde sabit genişlik */
        height: 120px; /* Mobilde sabit yükseklik */
    }
}
