/* General Styling */
.hakkimizda-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.left-column {
  flex: 1;
  padding-right: 20px;
  text-align: left;
}

.left-column h1 {
  font-size: 2rem;
  margin-bottom: 30px;
  text-align: left; 
  color: #2c3e50;
  font-weight: 700;
  padding-bottom: 10px;
  width: 100%;
}

.left-column p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  text-align: left;
}

.right-column {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.right-column img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: contain;
  transition: transform 0.3s ease; 
}

.right-column img:hover {
  transform: scale(1.05); 
}

/* Responsive Styling */
@media (max-width: 768px) {
  .hakkimizda-section {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }

  .left-column {
    padding-right: 0;
    text-align: left;
  }

  .left-column h1 {
    font-size: 2rem;
    text-align: left;
  }

  .left-column p {
    font-size: 1rem;
    text-align: left;
  }

  .right-column {
    justify-content: flex-start;
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .left-column h1 {
    font-size: 1.8rem;
    text-align: left;
  }

  .left-column p {
    font-size: 0.95rem;
    text-align: left;
  }

  .right-column img {
    width: 100%;
    height: auto;
  }
}
